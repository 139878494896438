<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          เลือกรายการ
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-row class="mx-auto">
          <b-col cols="12 col-md-4">
            <b-form-group>
              <v-select
                v-model="selected"
                :options="option"
                label="title"
              >
                <template #option="{ title }">
                  {{ title }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12 col-md-4 mb-2">
            <b-input-group>
              <b-input-group-prepend>
                <b-button variant="outline-primary">
                  <feather-icon icon="ClockIcon" />
                </b-button>
              </b-input-group-prepend>
              <flat-pickr
                v-model="date"
                class="form-control"
                :config="{ enableTime: true, mode: 'range', dateFormat: 'Y-m-d H:i', defaultHour: 11 }"
              />
              <b-input-group-append>
                <b-button variant="outline-primary" @click="search_report_from_date()">
                  ค้นหา
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12 col-md-4 mb-2">
            <button @click="search_report()" type="button" class="btn bg-gradient-primary waves-effect waves-light text-white mr-1 mb-2">ค้นหา</button>
            <button @click="save_report()" type="button" class="btn bg-gradient-success waves-effect waves-light text-white mr-1 mb-2">บันทึก</button>
            <button id="btn_export_excel" type="button" class="btn bg-gradient-warning waves-effect waves-light text-white mb-2">ออกรายงาน Excel</button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-row>
      <b-col cols="lg-8 col-md-12 col-12">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between align-items-end">
            <h4 class="mb-0">
              สรุปรายการ
            </h4>
          </b-card-header>
          <b-card-body class="px-0 pb-0">
            <b-row class="text-center mx-0">
              <b-col
                cols="lg-3 col-6"
                class="border-top border-right d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  สมาชิก
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toNum(result.txt_report_member) }}
                </p>
              </b-col>
              <b-col
                cols="lg-3 col-6"
                class="border-top border-right d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  รายการฝาก
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toNum(result.txt_report_deposit_count) }}
                </p>
              </b-col>
              <b-col
                cols="lg-3 col-6"
                class="border-top border-right d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  รายการถอน
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toNum(result.txt_report_withdraw_count) }}
                </p>
              </b-col>
              <b-col
                cols="lg-3 col-6"
                class="border-top d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  รวมรายการ
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toNum(result.txt_report_all_count) }}
                </p>
              </b-col>
              <b-col
                cols="lg-3 col-6"
                class="border-top border-right d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  ยอดฝาก
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toMoney(result.txt_report_deposit) }} ฿
                </p>
              </b-col>
              <b-col
                cols="lg-3 col-6"
                class="border-top border-right d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  ยอดถอน
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toMoney(result.txt_report_withdraw) }} ฿
                </p>
              </b-col>
              <b-col
                cols="lg-3 col-6"
                class="border-top border-right d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  กำไรสุทธิ์
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toMoney(result.txt_report_deposit - result.txt_report_withdraw) }} ฿
                </p>
              </b-col>
              <b-col
                cols="lg-3 col-6"
                class="border-top d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  ยอดรวม
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toMoney(result.txt_report_total) }} ฿
                </p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="lg-4 col-md-12 col-12 col-sm-12">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between align-items-end">
            <h4 class="mb-0">
              โปรโมชั่น
            </h4>
          </b-card-header>
          <b-card-body class="px-0 pb-0">
            <b-row class="text-center mx-0">
              <b-col
                cols="12"
                class="border-top d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  โปรโมชั่น
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toNum(result.txt_report_promotion_count) }}
                </p>
              </b-col>
              <b-col
                cols="12"
                class="border-top d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  เครดิตโปรโมชั่น
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toMoney(result.txt_report_promotion) }} ฿
                </p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- สิ้นสุดท้าย -->
    <b-row>
      <!-- สรุปยอดเอเย่นต์ -->
      <b-col cols="12 col-md-8">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0 danger">
              สรุปยอดเอเย่นต์
            </h4>
          </b-card-header>
          <div class="card-content">
            <b-table
              :fields="fieldAgent"
              :items="tableAgent"
              responsive
              table-class="table-hover" show-empty>
              <template #empty="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #emptyfiltered="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #cell(deposit)="data">
                {{ toMoney(data.value) }}
              </template>
              <template #cell(withdraw)="data">
                {{ toMoney(data.value) }}
              </template>
              <template #cell(vat)="data">
                {{ toMoney(data.value) }}
              </template>
              <template #cell(pro)="data">
                {{ toMoney(data.value) }}
              </template>
              <template #cell(total)="data">
                {{ toMoney(data.value) }}
              </template>
            </b-table>
          </div>
        </b-card>
      </b-col>
      <!-- สรุปยอดโปรโมชั่น -->
      <b-col cols="12 col-md-4">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0 danger">
              สรุปยอดโปรโมชั่น
            </h4>
          </b-card-header>
          <div class="card-content">
            <b-table
              :fields="fieldPromotion"
              :items="tablePromotion"
              responsive
              table-class="table-hover" show-empty>
              <template #empty="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #emptyfiltered="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #cell(total)="data">
                {{ toMoney(data.value) }}
              </template>
            </b-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BFormGroup, BInputGroupAppend,
  BInputGroupPrepend, BButton, BFormInput, BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BFormGroup,
    BInputGroupAppend, BInputGroupPrepend, BButton, BFormInput, BInputGroup,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      BASE_URL: process.env.BASE_URL,
      selected: { title: 'เลือกรายการสรุป', value: '' },
      option: [
        { title: 'เลือกรายการสรุป', value: '', disabled: true },
      ],
      date: null,
      result: {
        txt_report_member: 0,
        txt_report_deposit_count: 0,
        txt_report_withdraw_count: 0,
        txt_report_all_count: 0,
        txt_report_register: 0,
        txt_report_deposit: 0,
        txt_report_withdraw: 0,
        txt_report_total: 0,
        txt_report_promotion_count: 0,
        txt_report_promotion: 0,
      },
      t_fetch: 0,
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
      fieldPromotion: [
        { key: 'name', label: 'ชื่อโปรฯ' },
        { key: 'total', label: 'สุทธิ', class: 'text-right' },
      ],
      tablePromotion: [
        {
          name: 'คืนยอดเสีย', amount: 0,
        },
      ],
      fieldAgent: [
        { key: 'prefix', label: 'ชื่อ' },
        { key: 'deposit', label: 'ฝาก', class: 'text-right' },
        { key: 'withdraw', label: 'ถอน', class: 'text-right' },
        { key: 'vat', label: 'ธรรมเนียม', class: 'text-right' },
        { key: 'pro', label: 'โปรฯ', class: 'text-right' },
        { key: 'total', label: 'กำไรสุทธิ์', class: 'text-right' },
      ],
      tableAgent: [
        {
          prefix: 'ADMIN', deposit: 0, withdraw: 0, fee: 0, pro: 0, amount: 0,
        },
      ],
    }
  },
  mounted() {
    // this.fetch()
    this.fetch_report_list()
    /*this.t_fetch = setInterval(() => {
      this.fetch()
    }, 1000 * 60)*/
  },
  methods: {
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    toNum(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    load(data) {
      if (data == null || data.result == null) return;
      this.result = data.result
      if (data.result.agents != null) {
        this.tableAgent = data.result.agents
      }
      if (data.result.promotions != null) {
        this.tablePromotion = data.result.promotions
      }
    },
    fetch() {
      this.loading()
      this.$http.get('report/report_list').then(res => { this.load(res.data); this.$swal.close() }).catch(()=>this.$swal.close())
    },
    fetch_report_list() {
      this.$http.get('report/report_list_select').then(res => { res.data.result.forEach(data=> this.option.push({title: `#${data.id} ${data.name}`, value: data.id })) })
    },
    search_report() {
      this.$http.post('report/report_history', { report_id: Number(this.selected.value || 1) }).then(res => { this.load(res.data) })
    },
    search_report_from_date() {
      if (this.date == null || this.date == "") return;
      let date = this.date.split(' to ');
      if (!date || date.length < 2) return;
      let from_date = date[0];
      let to_date = date[1];
      this.$http.get('report/report_list?from_date=' + from_date + '&to_date=' + to_date).then(res => { this.load(res.data); this.$swal.close() }).catch(()=>this.$swal.close())
    },
    save_report() {
      this.loading()
      this.$http.post('report/report_save').then(res => { this.load(res.data); this.$swal.close() }).catch(()=>this.$swal.close())
    },
  },
  beforeRouteLeave (to, from, next) {
    // clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
